/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Titan+One&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(263, 34%, 46%);
  --first-color-dark: hsl(272, 56%, 24%);
  --first-color-darken: hsl(272, 57%, 18%);
  --second-color: hsl(45, 100%, 52%);
  --second-color-dark: hsl(45, 100%, 20%);
  --white-color: hsl(0, 0%, 100%);
  --shadow-img: drop-shadow(0 12px 24px hsla(0, 0%, 0%, .4));
  --shadow-circle: 0 12px 24px hsla(0, 0%, 0%, .4);
  --shadow-button: 0 -4px 8px hsla(0, 0%, 0%, .3);
  --shadow-card: 0 -8px 24px hsla(0, 0%, 0%, .3);
  --shadow-text: 0 2px 4px var(--first-color-darken);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --second-font: "Titan One", sans-serif;
  --biggest-font-size: 3.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1150px) {
  :root {
    --biggest-font-size: 5.5rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--first-color);
  color: var(--white-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section {
  padding-block: 5rem 1rem;
}

.section__title {
  font: var(--font-regular) var(--h1-font-size) var(--second-font);
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: var(--shadow-text);
}

.main {
  overflow: hidden;
}

/*=============== HEADER & NAV ===============*/

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--first-color);
  z-index: var(--z-fixed);
  transition: box-shadow .45;
}

.nav {
  position: relative;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  color: var(--white-color);
  font-family: var(--second-font);
  text-shadow: var(--shadow-text);
}

.nav__close,
.nav__toggle {
  display: flex;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Navigation for mobile devices */
@media screen and (max-width:1150px) {
  .nav__menu {
    position: fixed;
    left: -100%;
    top: 0;
    background-color: var(--first-color-dark);
    width: 100%;
    height: 100%;
    padding: 7rem 1.5rem 2.5rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: left .4s;
  }
}

.nav__list,
.nav__social {
  display: flex;
}

.nav__list {
  flex-direction: column;
  row-gap: 3rem;
}

.nav__link {
  color: var(--white-color);
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  transition: color .4s;
}

.nav__link:hover {
  color: var(--second-color);
}

.nav__close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.nav__social {
  column-gap: 1rem;
  align-self: flex-end;
}

.nav__social-link {
  columns: var(--white-color);
  font-size: 1.5rem;
  transition: color .4s;
}

.nav__social-link:hover {
  color: var(--second-color);
}


/* Show menu */
.show-menu {
  left: 0;
}


/* Shadow header */
.show-header {
  box-shadow: 0 2px 16px hsla(0, 0%, 0%, .15);
}


/* Active link */


/*=============== HOME ===============*/
.home__container {
  padding-top: 1.5rem;
  justify-content: center;
  row-gap: 4rem;
}

.home__data {
  position: relative;
  text-align: center;
}

.home__title {
  font: var(--font-regular) var(--biggest-font-size) var(--second-font);
  line-height: 100%;
  margin-bottom: 1rem;
  text-shadow: var(--shadow-text);
}

.home__meat {
  width: 40px;
  position: absolute;
  top: 9rem;
  right: 0;
  opacity: .5;
}

.home__description {
  margin-bottom: 2.5rem;
}

.home__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.home__images {
  position: relative;
  display: grid;
  place-items: center;
  justify-self: center;
}

.home__circle {
  width: 420px;
  height: 420px;
  background-color: var(--first-color-darken);
  border-radius: 50%;
  box-shadow: inset var(--shadow-circle);
  display: grid;
  place-items: center;
}

.home__images .home__img {
  width: 350px;
  /* rotate: 10deg; */
  filter: var(--shadow-img);
  position: absolute;
}

.home__subcircle {
  width: 350px;
  height: 350px;
  background-color: white;
  border-radius: 50%;
  box-shadow: inset var(--shadow-circle);
}

.home__images img {
  width: 60px;
  filter: var(--shadow-img);
  position: absolute;
}

.home__chips-1 {
  right: -.5rem;
}

.home__chips-2 {
  bottom: -.75rem;
  right: -.5rem;
}

.home__chips-3 {
  top: .25rem;
  left: -.5rem;
}

.home__tomato-1 {
  top: -1.5rem;
  right: 2.5rem;
}

.home__tomato-2 {
  left: 3.5rem;
  bottom: -1.5rem;
}

.home__leaf {
  left: -.75rem;
}

.button {
  background-color: var(--second-color);
  color: var(--second-color-dark);
  font-weight: var(--font-semi-bold);
  padding: .75rem 2rem;
  border-radius: 4rem;
  box-shadow: inset var(--shadow-button);
}

.button__ghost {
  background-color: transparent;
  box-shadow: none;
  border: 3px solid var(--second-color);
  color: var(--second-color);
}


/*=============== BUTTON ===============*/


/*=============== FAVORITES ===============*/

.favorites__container {
  row-gap: 3rem;
  grid-template-columns: 100%;
  padding-bottom: 3rem;
}

.favorites__data {
  position: relative;
  text-align: center;
}

.favorites__cheese-1,
.favorites__cheese-2 {
  width: 40px;
  position: absolute;
  opacity: .5;
}

.favorites__cheese-1 {
  top: 2rem;
  right: -1rem;
  rotate: -15deg;
}

.favorites__cheese-2 {
  bottom: -2rem;
  left: -.75rem;
  transform: scaleX(-1) rotate(45deg);
}

.favorites__article {
  position: relative;
  width: 200px;
}

.favorites__img {
  width: 400px;
  scale: .8;
  transition: scale 4s, filter .4s;
}

.favorites__leaf-1,
.favorites__leaf-2 {
  width: 70px;
  position: absolute;
  filter: var(--shadow-img);
  scale: 0;
  transition: scale .4s .1s;
}

.favorites__leaf-1 {
  top: -1rem;
  right: -.5rem;
}

.favorites__leaf-2 {
  bottom: -1rem;
  left: -.5rem;
}

/* Swiper class */
.swiper {
  margin-inline: initial;
  overflow: visible;
}

:is(.swiper-slide-active, .swiper-slide-duplicate-active) .favorites__img {
  scale: 1;
  filter: var(--shadow-img);
}

:is(.swiper-slide-active, .swiper-slide-duplicate-active) .favorites__leaf-1,
:is(.swiper-slide-active, .swiper-slide-duplicate-active) .favorites__leaf-2 {
  scale: 1;
}

/*=============== CARE ===============*/
.care {
  background-color: var(--first-color-dark);
}

.care__container {
  row-gap: 3rem;
  padding-block: 1rem 2rem;
}

.care__list {
  display: grid;
  row-gap: 1.25rem;
}

.care__item {
  display: flex;
  column-gap: .75rem;
  align-items: center;
}

.care__item i {
  font-size: 1.5rem;
  color: var(--second-color);
}

.care__img {
  width: 320px;
  filter: var(--shadow-img);
  justify-self: center;
}

/*=============== BANNER ===============*/

.banner__container {
  background-color: var(--second-color);
  padding: 1rem 1.5rem;
}

.banner__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
}

.banner__item {
  color: var(--second-color-dark);
  display: flex;
  align-items: center;
  column-gap: .5rem;
}

.banner__item i {
  font-size: 1.5rem;
}

.banner__item span {
  font:
    var(--small-font-size) var(--second-font);
}

/*=============== PRODUCTS ===============*/


/*=============== CONTACT ===============*/
.contact__container {
  position: relative;
  background-color: var(--first-color-darken);
  padding-top: 3.5rem;
  border-radius: 3rem;
  box-shadow: inset var(--shadow-card);
  overflow: hidden;
}

.contact__content {
  padding-top: 1rem;
  row-gap: 3rem;
}

.contact__data {
  text-align: center;
}

.contact__title {
  font: var(--font-regular) var(--h2-font-size) var(--second-font);
  margin-bottom: .5rem;
}

.contact__social {
  display: flex;
  justify-content: center;
  column-gap: .75rem;
}

.contact__social a {
  color: var(--second-color);
  font-size: 1.5rem;
}

.contact__info {
  font-style: normal;
}

.contact__img {
  width: 300px;
  justify-self: center;
  filter: var(--shadow-img);
}

.contact__shrimp,
.contact__crab,
.contact__meat {
  position: absolute;
  width: 40px;
  opacity: .5;
}

.contact__shrimp {
  top: 9rem;
  right: 1.5rem;
  rotate: 15deg;
}

.contact__crab {
  top: 17rem;
  left: 1rem;
  rotate: 15deg;
}

.contact__meat {
  right: 2rem;
  bottom: 11rem;
  rotate: -15deg;
}

/*=============== FOOTER ===============*/

.footer {
  padding-block: 3rem 2rem;
}

.footer__container {
  row-gap: 2rem;
}

.footer__logo {
  color: var(--white-color);
  font: var(--h1-font-size) var(--second-font);
  justify-self: center;
  text-shadow: var(-shadow-text);
}

.footer__content {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  row-gap: 3.5rem;
}

.footer__copy {
  grid-column: 1/3;
  text-align: center;
}

.footer__link {
  color: var(--white-color);
  transition: color 45;
}

.footer__link:hover {
  color: var(--second-color);
}

/*=============== SCROLL BAR ===============*/

::-webkit-scrollbar {
  width: .6rem;
  background-color: hsl(263, 34%, 46%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(272, 56%, 28%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(272, 56%, 17%);
}

/*=============== SCROLL UP ===============*/


/* Show Scroll Up */


/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .container {
    margin-inline: 1rem;
  }

  .section__title {
    font-size: var(--h2-font-size);
  }

  .home__title {
    font-size: 3rem;
  }

  .home__buttons {
    flex-direction: column;
    row-gap: 1rem;
  }

  .home__circle {
    width: 250px;
    height: 250px;
  }

  .home__subcircle {
    width: 200px;
    height: 200px;
  }

  .home__images .home__img {
    width: 200px;
  }

  .footer__content {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 1.5rem;
  }

  .footer__copy {
    grid-column: 1;
  }

}

/* For medium devices */
@media screen and (min-width: 540px) {

  .home__container,
  .favorites__container,
  .care__container {
    grid-template-columns: 370px;
    justify-content: center;
  }

  .favorites__swiper {
    max-width: 600px;
    overflow-x: clip;
    justify-self: center;
  }

  .contact {
    display: grid;
    grid-template-columns: 450px;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .contact {
    grid-template-columns: 550px;
  }

  .footer__content {
    grid-template-columns: repeat(3, max-content);
    justify-content: space-between;
    align-items: center;
  }

  .footer__copy {
    grid-column:
      initial;
  }

  .footer__link:nth-child(2) {
    order: 1;
  }
}

/* For large devices */
@media screen and (min-width:1150px) {
  .container {
    margin-inline: auto;
  }

  .section {
    padding-block: 7rem 2rem;
  }

  .section__title {
    margin-bottom: 2rem;
  }

  nav {
    height: calc(var(--header-height) + 2rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__link {
    font-size: var(--normal-font-size);
  }

  .nav__menu {
    display: flex;
    column-gap: 3rem;
    align-items: center;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 4rem;
  }

  .home__container {
    grid-template-columns: 420px 585px;
    align-items: center;
    column-gap: 6rem;
    padding-top: 5rem;
  }

  .home__data {
    text-align: initial;
  }

  .home__title {
    margin-bottom: 1.5rem;
  }

  .home__description {
    margin-bottom: 4rem;
  }

  .home__buttons {
    justify-content: initial;
    column-gap: 1.5rem;
  }

  .home__meat {
    width: 80px;
    top: 13rem;
  }

  .home__circle {
    width: 550px;
    height: 550px;
  }

  .home__subcircle {
    width: 430px;
    height: 430px;
  }

  .home__images .home__img {
    width: 400px;
  }

  .home__images img {
    width: 95px;
  }

  .favorites__container {
    grid-template-columns: 520px;
    row-gap: 4rem;
  }

  ul {
    list-style-type: none; /* Remove bullets or markers */
  }

  a {
    text-decoration: none;}

  .favorites__cheese-1,
  .favorites__cheese-2 {
    width: 80px;
  }

  .favorites__cheese-1 {
    right: -18rem;
  }

  .favorites__cheese-2 {
    left: -18rem;
  }

  .favorites__swiper {
    max-width: 1000px;
  }

  .favorites__article,
  .favorites__img {
    width: 420px;
  }

  .favorites__leaf-1,
  .favorites__leaf-2 {
    width: 110px;
  }

  .care__container {
    grid-template-columns: 500px 410px;
    column-gap: 6rem;
    align-items: center;
    padding-block: 4rem;
  }

  .care__img {
    width: 550px;
    order: -1;
  }

  .care__item {
    column-gap:
      1rem;
  }

  .care__item i {
    font-size: 2rem;
  }

  .banner {
    padding-top: 4rem;
  }

  .banner__list {
    column-gap: 6.5rem;
  }

  .banner__item {
    column-gap: 1rem;
  }

  .banner__item i {
    font-size: 4.5rem;
  }

  .banner__item span {
    font-size: var(--h1-font-size);
  }

  .contact {
    display: block;
  }

  .contact__container {
    border-radius: 4rem;
  }

  .contact__content {
    grid-template: 330px / 360px 320px;
    justify-content: center;
    align-items: flex-start;
    column-gap: 10rem;
    padding-top: 2rem;
  }

  .contact__data {
    text-align: initial;
    grid-template: max-content / repeat(2, max-content);
    gap: 3rem 2rem;
  }

  .contact__title {
    margin-bottom: .75rem;
  }

  .contact__social {
    justify-content: initial;
  }

  .contact__img {
    width: 320px;
    transform: translateY(-6rem);
  }

  .contact__shrimp,
  .contact__crab,
  .contact__meat {
    width: 80px;
  }

  .contact__shrimp {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 20rem;
  }

  .contact__crab {
    top: 5rem;
    left: 4rem;
  }

  .contact__meat {
    right: initial;
    left: 12rem;
    bottom: -1rem;
  }

  .footer {
    padding-block: 5rem 3rem;
  }

  .footer__container {
    row-gap:
      3rem;
  }

}

/*=============== PROGRAMS ===============*/
.programs {
  padding: 2rem 0;
}

.programs__container {
  display: grid;
  gap: 1.5rem;
}

.programs__item {
  background: var(--white-color); /* White background */
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--first-color-dark); /* Dark text color for contrast */
}

.programs__title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.programs__description {
  font-size: 1rem;
  line-height: 1.6;
}


/*=============== JOIN US ===============*/
.join-us {
  background-color: var(--first-color-dark); /* Background color for the section */
  padding: 3rem 1.5rem;
  border-radius: 8px;
  box-shadow: inset var(--shadow-card);
}

.join-us__content {
  text-align: center;
}

.join-us__title {
  font: var(--font-regular) var(--h2-font-size) var(--second-font);
  margin-bottom: 1rem;
  color: var(--white-color);
}

.join-us__description {
  font-size: var(--normal-font-size);
  line-height: 1.6;
  color: var(--white-color);
  margin-bottom: 2rem;
}

.button1 {
  background-color: var(--second-color);
  color: var(--second-color-dark);
  font-weight: var(--font-semi-bold);
  padding: .75rem 2rem;
  border-radius: 4rem;
  box-shadow: inset var(--shadow-button);
  text-decoration: none;
}

/* Get Involved Section */
.get-involved {
  padding: 2rem 0;
  /* background-color: var(--first-color-dark); */
}

.get-involved__container {
  display: grid;
  gap: 1.5rem;
  max-width: 1120px;
  margin-inline: auto;
  padding: 1.5rem;
}

.get-involved__content {
  background: var(--white-color);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: var(--shadow-card);
  color: var(--first-color-darken);
}

.get-involved__title {
  font: var(--font-regular) var(--h2-font-size) var(--second-font);
  margin-bottom: 1rem;
  text-align: center;
}

.get-involved__description {
  font-size: var(--normal-font-size);
  line-height: 1.6;
  margin-bottom: 1rem;
}


/* FAQ Section */
.faq {
  padding: 2rem 0;
  background-color: #f4f4f4;
}

.faq__container {
  max-width: 1120px;
  margin: auto;
  padding: 1.5rem;
}

.faq__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--first-color-dark);
}

.faq__item {
  margin-bottom: 1rem;
}

.faq__question {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--second-color);
  margin-bottom: 0.5rem;
}

.faq__answer {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--first-color-dark);
}


/* src/AddMember.css */

.add-member {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-member__container {
  padding: 2rem;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-member__title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.add-member__subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.add-member__form {
  display: flex;
  flex-direction: column;
}

.add-member__form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.add-member__form input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-member__button {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.add-member__button:hover {
  background-color: #0056b3;
}

/* Add navigation styling if needed */
header {
  background: #333;
  color: #fff;
  padding: 1rem;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0;
}

nav ul li {
  margin-right: 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

nav ul li a.active {
  text-decoration: underline;
}
